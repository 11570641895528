import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Log } from "@services/log";
import { FormGroupDeclaration } from '@wearewarp/ng-form';

@Component({
  selector: 'confirm-unassign-carrier',
  templateUrl: './index.html',
  styleUrls: ['../../dialogs/dialogs.scss', './index.scss']
})

export class ConfirmUnassignCarrier extends BaseFormDialog1 {
  private _jobId: string;
  
  @Input() get jobId() {return this._jobId}
  set jobId(value: string) {
    this._jobId = value;
    this.getData();
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    code: { label: "", required: true },
    subCode: { label: "", required: false }
  };

  public isLoading: boolean = false;
  private getData() {
    if (!this.jobId) return Log.e('jobId is required');
    this.isLoading = true;
    this.api.concurrentGET([
      `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}`,
      Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/DISPATCH_UNASSIGN_REASON/unassign_reason_settings`)
    ]).subscribe(
      resp => {
        this.isLoading = false;
        const [jobResp, reasonResp] = resp;
        const value = reasonResp.value || '[]';
        const settings = JSON.parse(value);
        this.reasons = settings;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  public reasons = [];

  isShowChild(item): boolean {
    return this.getItemValue('code') === item.value;
  }

  onChangeReason(data) {
    const item = this.reasons.find(x => x.value === data);
    const options = item?.options || [];
    let isRequired = false;
    if(options.length) {
      this.formGroupDeclaration.subCode.required = true;
      isRequired = true;
    }
    else {
      this.formGroupDeclaration.subCode.required = false;
      this.setItemValue('subCode', '');
    }

    let fc = <FormControl>this.formInput.get('subCode');
    if (fc) {
      if (!isRequired) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  onBtnConfirm() {
    if (!this.jobId) return Log.e('jobId is required');
    let json: any = this.getFormData_JSON(true);
    const code = json.subCode || json.code;
    const item = this.reasons.find(x => x.value === json.code);
    let reason = item?.label || '';
    if(item?.options?.length) {
      reason = item.options.find(x => x.value === json.subCode)?.label || '';
    }

    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/unassign_carrier`, {
      code, reason
    }).subscribe(
      resp => {
        this.stopProgress();
        Log.d('unassignCarrier done ', resp);
        this.onUpdateSuccess({ ui_message: 'Unassign Carrier done' });
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

}
