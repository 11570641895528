export enum ApiMethod {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete'
}

export enum ProfileTab {
  playlist = 'playlist',
  history = 'history',
  message = 'message',
  setting = 'setting'
}

export enum AppEvent {
  socketEvent = 'socketEvent',
  badgeUpdated = 'badgeUpdated'
}

export enum SocketEvent {
  welcome = 'welcome',
  jobFollow = 'jobFollow',
  jobRequestLiveStatus = 'jobRequestLiveStatus',
  jobStatusChange = 'jobStatusChange',
  taskStatusChange = 'taskStatusChange',
  driverAppCommand = 'driverAppCommand',
  driverFollow = 'driverFollow',
  driverRequestOnlineStatus = 'driverRequestOnlineStatus',
  driverOnlineStatus = 'driverOnlineStatus',
  driverRequestMobileAppStatus = 'driverRequestMobileAppStatus',
  driverMobileAppStatus = 'driverMobileAppStatus',
  driverRequestStartBackgroundLocation = 'driverRequestStartBackgroundLocation',
  driverRequestLastKnownLocation = 'driverRequestLastKnownLocation',
  driverLocation = 'driverLocation',
  driverTrackLocation = 'driverTrackLocation',
  driverUnTrackLocation = 'driverUnTrackLocation',
  /**
   * @deprecated use driverLocation instead
   */
  updateDriverLocation = 'updateDriverLocation',
}

export enum FuelCostType {
  percentage = 'percentage',
  ratePerMile = 'rpm'
}

export enum VolumeDiscountType {
  percentage = 'percentage',
  flatRate = 'flatRate'
}

export enum ShipmentEntryCreateMode {
  single = 'single',
  batch = 'batch',
  editDraft = 'editDraft',
}

export enum ShipmentManifestCreateMode {
  warpCarrier = 'warpCarrier',
  thirdPartyCarrier = 'thirdPartyCarrier',
}
