<div class="modal-content">
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical" *ngIf="!isLoading">
    <div nz-col nzSpan="24">
      <div class="content">Select reason to unassign</div>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-control>
          <nz-radio-group formControlName="code" (ngModelChange)="onChangeReason($event)">
            <label nz-radio *ngFor="let item of reasons" [nzValue]="item.value" class="flex pb-10">
              {{item.label}}<br />
              <span style="font-style: italic;" *ngIf="isShowChild(item) && item?.description">{{ item?.description }}</span>
              <div class="sub-options" style="padding-top: 12px;" *ngIf="isShowChild(item) && item?.options?.length">
                <nz-radio-group formControlName="subCode">
                  <label nz-radio *ngFor="let option of item.options" [nzValue]="option.value" class="flex pb-10">
                    {{option.label}}<br />
                  </label>
                </nz-radio-group>
              </div>
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="!onProgress" 
  [canClickOK]="!onProgress && needUpdate"
  labelOK="Unassign"
  (onOK)="onBtnConfirm()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
