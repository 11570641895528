import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DialogService } from "./dialog.service";
import { ShipmentEntryPreCreate } from "@app/admin/shipment-entry/precreate";
import { ShipmentEntryCreateMode } from "@app/enum";
import { ShipmentEntryContainer } from "@app/admin/shipment-entry/container";
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { Order } from "@wearewarp/types/data-model";

@Component({
  selector: 'dlg-create-success',
  templateUrl: './dlg-create-success.html',
  styleUrls: ['./dialogs.scss', '../app.scss']
})
export class DlgCreateSuccessComponent extends BaseComponent {

  @Input() shouldShowBtnCreateNewShpment: boolean = true;
  @Input() order: Order = {};
  @Input() showCreateNewBtn: boolean = true;

  constructor(private modal: NzModalRef) {
    super();
  }

  private _onProgress = false;

  get onProgress() {
    return this._onProgress;
  }

  private get orderId() {
    return this.order?.id
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  createNewShipment() {
    this.openDialogPrecreate()
    this.closeDialog()
  }

  viewShipment() {
    this.closeDialog()
  }

  onDone() {
    this.closeDialog()
  }

  openDialogPrecreate() {
    DialogService.openDialog(ShipmentEntryPreCreate, {
      replaceWrapClassName: true,
      nzClassName: '',
      nzCentered: true,
      nzComponentParams: {
        onOk: (type: ShipmentEntryCreateMode, model) => {
          this.openDialogShipmentEntry({ type, modelDraft: model })
        }
      }
    });
  }

  openDialogShipmentEntry(ops: { type?: ShipmentEntryCreateMode, modelDraft?: any, model?: any }) {
    DialogService.openDialog(ShipmentEntryContainer, {
      replaceWrapClassName: true,
      nzClosable: false,
      nzClassName: 'modal-fullscreen modal-no-padding',
      nzComponentParams: {
        createMode: ops.type,
        modelDraft: ops.modelDraft,
        model: ops.model,
      }
    });
  }

  openOrder() {
    this.closeDialog();
    this.router.navigate([this.routeAdminShipmentList, this.orderId]);
  }

  private get trackingCode() {
    return this.order?.code || this.order?.trackingCode || ""
  }

  getTrackingLink() {
    return `${environment.trackingWebUrl}/${this.trackingCode}`;
  }

  copyTrackingLink() {
    let content = this.getTrackingLink();

    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy Tracking Link', 'Tracking Link has been copied to clipboard')
    })
  }
} 