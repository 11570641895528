import { Component } from '@angular/core';

@Component({
  selector: '[quickbooks-oauth-redirect]',
  templateUrl: './view.html',
  styleUrls: []
})
export class QuickbooksOAuthRedirect {

}
